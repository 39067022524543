.navbar-container {
  background-color: rgba(6, 29, 58, 0.58);
  position: fixed;
  z-index: 9999;
  margin-top: -5rem;
  width: 100%;
}

.bootstrap-container {
  width: 100vw;
}

.nav-center {
  margin: 0 auto;
  font-size: 1.5rem;
}

.text-white {
  padding-right: 10px;
}

.text-white:hover {
  cursor: pointer;
}

/* link hover effect */
.link-hover-effect {
  position: relative;
}

.link-hover-effect:before {
  content: "";
  /* background: rgba(0, 212, 255, 1); */
  background: red;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.link-hover-effect:hover::before {
  width: 100%;
}

.nav-sidebar-center {
  display: none;
  font-size: 2rem;
}

.nav-hamburger-container {
  color: white;
  cursor: pointer;
  display: none;
}

.nav-hamburger-container:hover .bar1,
.nav-hamburger-container:hover .bar2,
.nav-hamburger-container:hover .bar3 {
  background-color: red;
}

.nav-hamburger-icon {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  display: block;
  width: 30px;
  height: 4px;
  background-color: white;
  margin: 9px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 13px) rotate(-55deg);
  height: 6px;
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -13px) rotate(55deg);
  height: 6px;
}

@media screen and (min-width: 1400px) {
  .text-white {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .text-white {
    display: none;
  }
  .me-auto {
    margin: 0 !important;
  }
  .nav-hamburger-container {
    display: flex;
    margin: 0 1rem;
    width: 30px;
  }
  .nav-sidebar-container {
    display: flex;
    flex-direction: column;
    padding: 4rem 0 1rem 0;
    text-align: center;
    justify-content: space-evenly;
    position: absolute;
    left: 0;
    right: 0;
    top: -426px;
    bottom: 0;
    width: 100%;
    height: 500px;
    background-color: #061d3a;
    transition: all 0.3s ease;
    z-index: -1;
  }
  .nav-sidebar-show {
    top: 0;
  }
  .nav-sidebar-center {
    color: white;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .nav-sidebar-visible {
    display: block;
    transition: all 0.3s ease;
  }
  .skills-li-container {
    display: block !important;
  }
}/*# sourceMappingURL=nav.css.map */