.technologies-img {
  width: 3rem;
  height: auto;
  margin: 15px;
  transition: 1s ease;
}

.technologies-img:hover {
  transform: scale(1.2);
  transition: 1s ease;
}/*# sourceMappingURL=technologies.css.map */