.form-flex {
  display: flex;
}

.form-container {
  width: 100%;
  padding: 3rem;
  background-color: white;
}

.technologies-container {
  display: none;
  width: 50%;
  padding: 3rem 3rem;
  background-color: white;

  .technologies-links {
    width: 100%;
    height: 80%;
    padding-top: 2rem;
  }
}

.btn-color {
  background-color: red;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  border: none;

  .btn-color:hover {
    background-color: rgba(255, 6, 6, 0.74);
  }
}

.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 3px solid gray;
}

.form-control:focus {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 3px solid red;
}

@media screen and (min-width: 992px) {
  .form-container {
    width: 50%;
  }
  .technologies-container {
    display: block;
  }
}
