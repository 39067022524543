/* Project js */
.hero-container {
  flex-wrap: wrap;
  text-align: center;
  height: 46rem;
  margin: 0;
}
.hero-container .project-links {
  width: 100%;
  max-width: 100% !important;
  text-decoration: none;
}

.project-cards {
  height: 6rem;
  max-width: 35rem;
  color: #061d3a;
  background-color: white;
  border: none;
  border-radius: 0px;
  padding: 2rem;
  margin: auto;
  font-size: 1.5rem;
  background: linear-gradient(to left, white 34%, red 65%) right;
  background-size: 300% 100%;
  transition: 1s ease-in-out;
}

.link-one:hover,
.link-two:hover,
.link-three:hover,
.link-four:hover {
  color: white;
  background-color: red;
  cursor: pointer;
  background-position: left;
}

.loader-container {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}/*# sourceMappingURL=projects.css.map */