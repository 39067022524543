.react {
  border-bottom: 10px solid #61dafb;
}

.nodejs {
  border-bottom: 10px solid lightgreen;
}

.html {
  border-bottom: 10px solid #f06529;
}

.css {
  border-bottom: 10px solid #2965f1;
}

.javascript {
  border-bottom: 10px solid #f0db4f;
}

.django {
  border-bottom: 10px solid #145f42;
}

.python {
  background-image: linear-gradient(to right, #4b8bbe 50%, #ffe873 50%);
  background-position: bottom;
  background-size: 100% 10px;
  background-repeat: no-repeat;
  padding-bottom: 26px !important;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0;
}

.blogs-item-container {
  background-color: white;
  color: #061d3a;
  padding: 1rem;
  margin: 10px 0;
  transition: 1s ease;
  width: 30rem;
}

.blogs-item-container:hover {
  transform: scale(1.05);
  transition: 1s ease;
}

.blogs-desc {
  color: #061d3a;
}

.blogs-topic {
  font-weight: 500;
}

.blogsPadding {
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .blogs-container {
    display: block;
  }
  .blogs-item-container {
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  .blogs-container {
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 1200px) {
  .blogs-container {
    justify-content: space-between;
  }
}/*# sourceMappingURL=blogs.css.map */