.info-container {
  background-color: #061d3a;
  padding: 3rem 4rem;
  align-self: center;
}

.skills-container {
  padding: 1rem;
  border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.skills-percent-container {
  display: flex;
  align-items: center;
  height: 10px;
}
.skills-percent-container .skills-percent {
  font-size: 0.65rem;
  margin: 0 10px 0 0;
}
.skills-percent-container .skills-percent-bar {
  width: var(--percent);
  border: 5px solid red;
  border-radius: 10px;
  opacity: 1;
}

.pos-rel {
  position: relative;
}

.skills-fix-width {
  width: 100% !important;
}

@media screen and (min-width: 992px) {
  .info-container {
    margin-top: 0;
    padding: 3rem 4rem;
  }
  .skills-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .skills-container {
    width: 45%;
  }
}/*# sourceMappingURL=skills.css.map */