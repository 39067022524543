.eyesHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  font-size: 4rem;
  margin: 2rem 0;
}

.eyesLocationText {
  position: absolute;
  top: 10rem;
  font-weight: 500;
  color: #061d3a;
}

.eyesContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: calc(100vw - 5px);
  height: calc(100vh - 5rem);
  background-image: linear-gradient(to top, white, #183252 90%, transparent);
  overflow: hidden;
}

.eyesHead {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 15rem;
  height: 15rem;
  background-color: rgb(255, 0, 0);
  border-radius: 100%;
  box-shadow: 
  /* inset 0px 0px 30px 30px rgba(0, 0, 0, 0.3), */ 8px 17px 72px
    50px rgba(0, 0, 0, 0.39);
  transform-style: preserve-3d;
  transform: perspective(200px) rotateX(var(--yAngle, 0deg))
    rotateY(var(--xAngle, 0deg));
}

.eye {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background-color: #fff;
  /* box-shadow: inset -5px 0px 10px 0px rgba(0, 0, 0, 0.9); */

  /* !slants the eyes when moving */
  /* transform: translate(50px) rotate(var(--eyeAngle, 0deg)); */
}

.pupil {
  position: relative;
  width: 1rem;
  height: 1rem;
  background-color: black;
  border-radius: 100%;
  top: calc(50% - 8px);
  left: 5px;
}

@media screen and (max-width: 900px) {
  .eyesContainer {
    flex-direction: column;
  }
}
