.character-container {
  //   background-image: linear-gradient(to bottom, white, #183252 90%, transparent);
  background-color: white;
  height: 35rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .character-container {
    margin-top: -5rem;
  }
}
