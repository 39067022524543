.blog-center {
  text-align: center;
}

.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: "1200px") {
  .blog-container {
    padding: 0 3.5rem;
  }
}

@media screen and (min-width: "1400px") {
  .blog-container {
    padding: 0 9rem;
  }
}
